:root {
  --ait-blue: #003b94;
  --ait-yellow: #f4b235;
  --ait-white: #f1f1f1;
  --ait-gray: #27323f;
  --ait-black: #060101;

  --ace-pink: #ee70dc;
  --master-skyblue: #4c86e5;
  --pro-orange: #f27200;
  --basic-green: #00b70e;

  --ait-green: #008c00;
  --ait-orange: #f7612e;
  --ait-lightgray: #b8bab8;

  --denver-violet: #9e00dc;
  --ming-yellow: #ffc200;

  --header-height: 60px;
}

::-webkit-scrollbar {
  display: none;
}

::selection {
  background: var(--ait-blue);
  color: #fff;
}

body {
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.MuiTypography-root {
  word-break: keep-all;
}
.pageWrapper {
  overflow: scroll;
  background-color: #fff;
}

.contentWrapper {
  margin-top: var(--header-height);
  color: var(--ait-black);
  /* background-color: var(--ait-white); */
}

/* For Buttons */
button.Mui-focusVisible {
  display: none;
}

button.selected {
  background-color: var(--ait-blue);
  color: var(--ait-white);
  border-radius: 25px;
  /* border-radius: 0;
  border-bottom: 5px solid var(--ait-blue); */
}

button.selected:hover {
  background-color: var(--ait-blue);
  color: var(--ait-white);
}

.captionBox .blackButton {
  background-color: var(--ait-black);
  color: var(--ait-white);
  padding: 5px 15px;
}

.captionBox .blackButton:hover {
  background-color: var(--ait-black);
  color: var(--ait-white);
}

.instructors .instructorTitle,
.mapContainer .title,
.spaceGallery .title {
  margin-bottom: 20px;
}

.instructors .instructorTitle .titleBox,
.mapContainer .title .titleBox,
.spaceGallery .title .titleBox {
  margin-top: 50px;
  padding-top: 25px; /* origin is 20 */
  margin-bottom: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.instructors .instructorTitle .titleBox .MuiTypography-root,
.mapContainer .title .titleBox .MuiTypography-root,
.spaceGallery .title .titleBox .MuiTypography-root {
  font-size: 24px;
  font-family: "Pretendard-Bold";
  /* padding-left: 5px; */
}

.instructors .instructorTitle .titleBox .MuiBox-root,
.mapContainer .title .titleBox .MuiBox-root,
.spaceGallery .title .titleBox .MuiBox-root {
  width: auto;
  flex-grow: 1;
  margin: 0 2px 0 20px;
  border-top: 3px solid;
}

/* For contentWrapper */
.contentWrapper .arrowButton {
  width: 60px;
  height: 60px;
  background-color: #000;
  margin: 5px 30px 0px 5px;
}

.contentWrapper .arrowButton:hover {
  background-color: #000;
}

.contentWrapper .arrowButton:hover .icon {
  animation: hopping 1s infinite forwards;
}

@keyframes hopping {
  0% {
    transform: translateX(0px);
  }
  70% {
    transform: translateX(6px);
  }
  80% {
    transform: translateX(6px);
  }
  95% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* For PageTitle */
.pageTitle {
  text-align: center;
  padding: 2vw 0vh 2vw;
}

.pageTitle > .main {
  font-family: "Inter-Variable";
  font-size: 10vw;

  /* font-variation-settings: "wght" 100, "wdth" 85; */
  /* --delay: calc((var(--char-index) + 1) * 150ms); */
  /* animation: breathe 4000ms infinite forwards; */
  /* animation-delay: var(--delay); */

  font-weight: 700;
}

.pageTitle > .main .char {
  --delay: calc((var(--char-index) + 1) * 150ms);
  animation-delay: var(--delay);
}

.pageTitle > .sub {
  font-family: "Pretendard-Bold";
  font-size: 2vw;
}

@keyframes breathe {
  60% {
    font-variation-settings: "wght" 800, "wdth" 100;
  }

  100% {
    font-variation-settings: "wght" 100, "wdth" 85;
  }
}

/* For MainPage */
/* ... pageSlider */
.pageSlider {
  width: 100%;
  height: 540px;
  color: var(--ait-white);
  /* color: var(--ait-black); */
  display: flex;
  position: relative;
}

.pageSlider .background {
  background: var(--ait-black);
}

.pageSlider .slider {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.slideWrapper {
  width: fit-content;
  display: flex;
  flex-direction: row;
}

.slideWrapper .slide {
  width: 70vw;
  padding-left: 15vw;
  padding-right: 15vw;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.slideWrapper .bgVideo {
  width: 100vw;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.pageSlider .slider .controller {
  color: var(--ait-white);
  padding: 5px 3%;
  position: absolute;
  height: 100%;
  margin: auto 0;
}

.pageSlider .slider .controller:hover {
  background-color: transparent;
}

.pageSlider .slider .controller.left {
  left: 3%;
}

.pageSlider .slider .controller.right {
  right: 3%;
}

.pageSlider .slider .controller .Icon {
  font-size: 40px;
}

.pageSlider .slider .slideNavigator {
  width: 100%;
  position: absolute;
  bottom: 30px;
  display: flex;
  justify-content: center;
}

.pageSlider .slider .slideNavigator button {
  background-color: transparent;
  color: var(--ait-white);
}

.pageSlider .slider .slideNavigator button.selected {
  color: var(--ait-yellow);
}

.pageSlider .slider .slideNavigator .icon {
  font-size: 12px;
}

/* ... ... slide */
.slide.instructor .captionBox,
.slide.semesterStart .captionBox,
.slide .captionBox {
  padding-top: 100px;
  width: fit-content;
}

.slide.instructor .captionBox .titleBox,
.slide.semesterStart .captionBox .titleBox,
.slide .captionBox .titleBox {
  text-align: right;
  margin-bottom: 15px;
  margin-right: 25px;
}

.slide.semesterStart .captionBox .titleBox,
.slide .captionBox .titleBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.slide.instructor .captionBox .titleBox .title,
.slide.semesterStart .captionBox .titleBox .title,
.slide .captionBox .titleBox .title {
  font-family: "Pretendard-Black";
  font-size: 80px;
  line-height: 1.3;
}

.slide.instructor .captionBox .contentBox,
.slide.semesterStart .captionBox .contentBox,
.slide .captionBox .contentBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.slide.instructor .captionBox .contentBox .content,
.slide.semesterStart .captionBox .contentBox .content,
.slide .captionBox .contentBox .content {
  font-family: "Pretendard-Medium";
  margin-right: 20px;
  text-align: right;
}
.slide.semesterStart .captionBox .contentBox .content,
.slide .captionBox .contentBox .content {
  width: 320px;
}
.slide.instructor .captionBox .contentBox .content {
  width: 280px;
}

/* ... symbols */
.symbols {
  height: 380px;
  background-color: var(--ait-blue);
  color: var(--ait-white);
  padding: 0 15%;
  display: flex;
  position: relative;
}

.symbols .captionBox {
  width: 280px;
  height: fit-content;
  position: absolute;
  right: 15%;
  top: 13%;
  padding: 15px;
  background-color: rgba(22, 59, 144, 0.75);
  backdrop-filter: blur(5px);
  text-align: end;
}

.symbols .captionBox .title {
  font-family: "Pretendard-Bold";
  font-size: 36px;
  padding-bottom: 8px;
}

.symbols .captionBox .text {
  font-family: "Pretendard-Medium";
  font-size: 16px;
  padding-bottom: 13px;
}

.symbolContent {
  position: absolute;
  display: flex;
  align-items: baseline;
  text-shadow: 2px 2px 6px #040404;
}

.symbolContent .symbol {
  font-family: "Inter-Black";
  font-size: 100px;
  transition: color 0.2s;
}

.captionContent .caption {
  font-family: "Pretendard-ExtraBold";
  font-family: "Pretendard-Bold";
  font-size: 16px;
  color: var(--ait-yellow);
  color: #fff;
  padding-left: 10px;
  opacity: 0;
  transition: opacity 0.2s;
}

.symbolContent:hover .symbol {
  filter: drop-shadow(2px 2px 6px #040404);
}

.symbolContent:hover .caption {
  opacity: 1;
}

.symbolContent:nth-child(1) {
  left: 15%;
  top: 30%;
}

.symbolContent:nth-child(2) {
  left: 28%;
  top: 15%;
}

.symbolContent:nth-child(3) {
  left: 23%;
  top: 67%;
}

.symbolContent:nth-child(4) {
  left: 35%;
  top: 48%;
}

.symbolContent:nth-child(5) {
  left: 48%;
  top: 25%;
}

.symbolContent:nth-child(6) {
  left: 55%;
  top: 60%;
}

/* ... trackPreview */
.trackPreview {
  height: 380px;
  display: flex;
  justify-content: space-between;
  background-color: var(--ait-white);
  padding: 0 15%;
  overflow: hidden;
}

.trackPreview .captionBox {
  margin-top: 60px;
}
.trackPreview .captionBox .title {
  width: max-content;
  font-family: "Pretendard-Black";
  font-size: 40px;
}
.trackPreview .captionBox .text {
  font-family: "Pretendard-Medium";
  font-size: 18px;
  margin-top: 10px;
}
.trackPreview .captionBox .blackButton {
  margin-top: 15px;
}

.trackCards {
  display: flex;
  justify-content: flex-end;
  width: 70%;
  right: 0%;
}

.trackPreviewCard {
  width: 70px;
  margin: 10px 0 10px 15px;
  border-radius: 10px;
  background-color: var(--ait-white);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.4s;
}

.trackPreviewCard:nth-child(6n + 1) {
  background-color: #cfcbc9;
}
.trackPreviewCard:nth-child(6n + 2) {
  background-color: #ece8db;
}
.trackPreviewCard:nth-child(6n + 3) {
  background-color: #c1ccca;
}
.trackPreviewCard:nth-child(6n + 4) {
  background-color: #aebcc4;
}
.trackPreviewCard:nth-child(6n + 5) {
  background-color: #d3cbc7;
}
.trackPreviewCard:nth-child(6n) {
  background-color: #e1dacc;
}

.trackPreviewCard .titleBox {
  width: 300px;
  position: absolute;

  bottom: 80px;
  color: var(--ait-white);
}

.trackPreviewCard img {
  width: 300px;
  margin: 0 auto;
}

.trackPreviewCard .gradientBox {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.2) 20%,
    rgba(0, 0, 0, 0) 100%
  );
}
.trackPreviewCard .titleBox .title {
  font-family: "Pretendard-Bold";
  font-size: 24px;

  /* writing-mode: vertical-lr; */
  transform: rotate(-90deg);
  padding-left: 60px;
  text-orientation: upright;
}

.trackPreviewCard:hover .titleBox .title {
  transform: none;
  padding-left: 0;
}

.trackPreviewCard .titleBox .caption,
.trackPreviewCard .periodBox {
  visibility: hidden;
}

.trackPreviewCard:hover .titleBox .caption,
.trackPreviewCard:hover .periodBox {
  visibility: visible;
}

.trackPreviewCard:hover {
  width: 300px;
}

.trackPreviewCard:hover .titleBox {
  width: max-content;
  left: 15px;
  bottom: 20px;
}

.trackPreviewCard:hover .periodBox {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.trackPreviewCard:hover .periodBox .year,
.trackPreviewCard:hover .periodBox .month {
  color: var(--ait-white);
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  line-height: 80%;
}

.trackPreviewCard:hover .periodBox .year .value {
  font-family: "Pretendard-SemiBold";
  font-size: 36px;
}

.trackPreviewCard:hover .periodBox .year .unit {
  font-family: "Pretendard-SemiBold";
  font-size: 16px;
}

.trackPreviewCard:hover .periodBox .month .value {
  font-family: "Pretendard-SemiBold";
  font-size: 28px;
}

.trackPreviewCard:hover .periodBox .month .unit {
  font-family: "Pretendard-SemiBold";
  font-size: 16px;
}

/* ... LearningSpace */
.learningSpace {
  height: 35vw;
  overflow: hidden;
  display: flex;
  background-color: var(--ait-gray);
  position: relative;
  align-items: center;
}

.learningSpace .contentBox img.desker {
  height: 35px;
  filter: invert(100%) drop-shadow(2px 2px 2px var(--ait-gray));
}

.learningSpace .contentBox img.odd33 {
  height: 25px;
  filter: brightness(10) drop-shadow(2px 2px 2px var(--ait-gray));
}

.learningSpace .captionBox {
  width: max-content;
  height: max-content;
  padding: 15px 15px 15px 10px;
  /* backdrop-filter: blur(3px); */
  /* background-color: #27323fcc; */
  border-radius: 15px;
  text-align: end;
  display: flex;
  flex-direction: column;
  align-items: end;

  position: absolute;
  right: 15%;
}

.learningSpace .captionBox .titleBox {
  width: max-content;
  margin-bottom: 15px;
  text-align: center;
  color: var(--ait-white);
  /* background-color: #003b99; */
}

.learningSpace .captionBox .title {
  font-family: "Pretendard-Bold";
  font-size: 36px;
  line-height: 1.15;
}

.learningSpace .captionBox .contentBox {
  --shadow-width: 3px;

  color: var(--ait-black);
  color: var(--ait-white);
  text-shadow: -2px -2px var(--shadow-width) var(--ait-gray),
    2px -2px var(--shadow-width) var(--ait-gray),
    -2px 2px var(--shadow-width) var(--ait-gray),
    2px 2px var(--shadow-width) var(--ait-gray);
}

.learningSpace .captionBox .contentBox .logoBox {
  /* height: calc(20px + 1vw); */
  height: 32px;
  min-height: 30px;
  display: flex;
  justify-content: end;
  align-items: center;
  /* color: var(--ait-black); */

  margin-bottom: 10px;
}

.learningSpace .captionBox .contentBox .logoBox .ampersand {
  font-family: "Inter-SemiBold";
  font-size: 24px;
  font-size: 1.4em;
  margin-left: 10px;
  margin-right: 15px;
}

.learningSpace .captionBox .captionWrapper {
  display: flex;
  justify-content: flex-end;
  height: calc(17px + 1.2vw);
  align-items: center;
}

.learningSpace .captionBox .captionWrapper img {
  margin-left: 7px;
  margin-right: 5px;
}

.learningSpace .captionBox .contentBox .caption {
  width: max-content;
  font-family: "Pretendard-Medium";
  font-size: calc(14px + 0.47vw);
  /* font-size: 1.4em; */
}

.cardGallery {
  display: flex;
  flex-flow: wrap;
  position: relative;
  top: 40%;
  left: -7vw;
  transform: rotate(8deg);
}

.cardGallery .cardGroup {
  display: flex;
  flex-direction: column;
  position: relative;
}

.cardGallery.onViewport .card:nth-child(1) {
  animation: top-bottom 0.3s forwards;
  animation-delay: calc(var(--count-index) * 0.15s);
}

.cardGallery.onViewport .card:nth-child(2) {
  animation: bottom-top 0.3s forwards;
  animation-delay: calc(var(--count-index) * 0.15s);
}

@keyframes top-bottom {
  0% {
    visibility: visible;
    transform: translateY(-500px);
  }
  100% {
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes bottom-top {
  0% {
    visibility: visible;
    transform: translateY(500px);
  }
  100% {
    visibility: visible;
    transform: translateY(0);
  }
}

.cardGallery .cardGroup:nth-child(1) {
  top: -6vw;
}

.cardGallery .cardGroup:nth-child(2) {
  top: -18vw;
}

.cardGallery .cardGroup:nth-child(3) {
  top: -12vw;
}

.cardGallery .card {
  width: 20vw;
  height: 30vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #aebcc4;
  margin: 0.7vw;
  border-radius: 5px;
  box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.4);

  visibility: hidden;
}

.cardGallery .card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* height: 210%; */
  /* filter: brightness(115%) saturate(150%) contrast(90%); */
}

/* ... projectPreview */
.projectPreview {
  height: 600px;
  /* padding: 0 15%; */
}

.projectPreview .titleWrapper {
  padding: 50px 15% 20px 15%;
  /* padding-top: 50px; */
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 20px; */
}

.projectPreview .titleWrapper .title {
  font-family: "Pretendard-Bold";
  font-size: 32px;
}

.projectPreview .titleWrapper .caption {
  font-family: "Pretendard-Light";
  font-size: 20px;
}

.projectPreview .arrowButton {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}

/* ... ComponentSlider */
.projectPreview .slideWrapper {
  display: flex;
  justify-content: center;
  position: relative;
}
.projectPreview .componentSlider {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.projectPreview .componentSlider .controller {
  position: absolute;
  height: 100%;
  width: 15vw;
}

.projectPreview .componentSlider .controller.left {
  left: 0;
  /* background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.75) 100%
  ); */
  background: transparent;
}

.projectPreview .componentSlider .controller.right {
  right: 0;
  /* background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.75) 100%
  ); */
  background: transparent;
}

.projectPreview .componentSlider .controller .icon {
  font-size: 60px;
  color: var(--ait-white);
}

/* ... ProjectPreviewCard*/
.projectPreviewCard {
  margin: 20px;
  padding: 12px 15px 10px 15px;
  width: 240px;
  height: 360px;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #ddd;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;
}
.projectPreviewCard:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}
.projectPreviewCard .authorBox {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
}
.projectPreviewCard .authorBox .profileImgBox {
  width: fit-content;
  display: flex;
  position: relative;
  align-items: center;
}
.projectPreviewCard .authorBox .profileImgBox .outer {
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 25px;

  background: linear-gradient(
    180deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
}

.projectPreviewCard .authorBox .profileImgBox .inner {
  width: 44px;
  height: 44px;
  overflow: hidden;
  position: absolute;
  border-radius: 25px;
  margin-left: 3px;

  display: flex;
  justify-content: center;
}

.projectPreviewCard .nameBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-left: 8px;
}

.projectPreviewCard .nameBox .name {
  font-family: "Pretendard-Bold";
  line-height: 1.3;
}

.projectPreviewCard .nameBox .date {
  font-family: "Pretendard-Light";
  color: #777;
  font-size: 14px;
}

.projectPreviewCard .thumbnailBox {
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 10px;

  background-size: cover;
}

.projectPreviewCard .tagBox {
  /* border-bottom: 1px solid #ddd; */
  display: flex;

  margin-top: 5px;
  margin-bottom: 8px;
  padding: 0 5px;
}

.projectPreviewCard .tagBox .tag {
  border-radius: 25px;
  border: 1px solid #bbb;
  font-family: "Pretendard-Light";
  font-size: 14px;
  color: #333;
  padding: 2px 7px;
  margin-right: 8px;
}

.projectPreviewCard .captionBox {
  width: 100%;
  padding: 0 5px;
  font-family: "Pretendard-Light";
}

/* ... AcademicCalendar */
.academicCalendar {
  /* height: 320px; */
  height: 560px;
  padding: 0 10% 0 10%;
  /* background-color: var(--ait-white); */
  background-color: #fff;
  display: flex;
  /* align-items: flex-end; */
}

.academicCalendar .calendarWrapper {
  /* --horizontal-padding: 75px; */
  width: 50%;
  /* height: 100%; */
  padding: 20px 20px 30px 20px;
  background-color: #fff;
}

.academicCalendar .scheduleWrapper {
  --horizontal-padding: 30px;

  width: calc(50% - var(--horizontal-padding));

  padding-left: var(--horizontal-padding);
  /* margin-bottom: 56px; */
  margin-top: 80px;
}

.academicCalendar .scheduleWrapper .title {
  width: max-content;
  font-family: "Pretendard-Black";
  /* font-size: 36px; */
  font-size: 2em;
  margin-bottom: 10px;
}

.academicCalendar .dateBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  border-bottom: 1px solid #000;
}
.academicCalendar .dateBox:nth-last-child(1) {
  /* border-bottom: 0px; */
}
.academicCalendar .dateBox .date {
  width: 40%;
  display: flex;
  align-items: center;
}
.academicCalendar .dateBox .date .MuiTypography-root {
  font-family: "Pretendard-SemiBold";
}
.academicCalendar .dateBox .date .MuiTypography-root.start {
  width: 30%;
}
.academicCalendar .dateBox .date .MuiTypography-root.tilde {
  font-family: "Pretendard-Black";
  /* width: 10%; */
  margin: 0 13px 0 10px;
}
.academicCalendar .dateBox .date .MuiTypography-root.end {
  /* width: 40%; */
}
.academicCalendar .dateBox .text {
  font-family: "Pretendard-Light";
  /* width: 60%; */
}

.footer {
  background-color: var(--ait-black);
  color: var(--ait-white);
  padding: 60px 10% 40px 10%;
}

.footer .titleWrapper {
  padding-bottom: 40px;
}

.footer .titleWrapper .title {
  cursor: pointer;
}

.footer .titleWrapper .companyNum {
  font-family: "Pretendard-Bold";
  color: #bbb;
  padding: 10px 0 5px 0;
  font-size: 14px;
  /* text-align: center; */
}

.footer .titleWrapper .copyright {
  font-family: "Inter-Medium";
  padding: 0 0 10px 0;
}

.footer .infoWrapper {
  display: flex;
  flex-wrap: wrap;
}

.footer .infoWrapper .MuiBox-root {
  margin-bottom: 20px;
}

.footer .infoWrapper .title {
  width: 12.5vw;
  min-width: 160px;
  font-family: "Pretendard-Black";
  letter-spacing: 1.5px;
  font-size: 14px;
  color: #bbb;
  margin-bottom: 20px;
}

.footer .infoWrapper .share .links {
  display: flex;
}

.footer .infoWrapper .share .links .icon {
  font-size: 44px;
  margin-right: 20px;
  color: #fff;
  cursor: pointer;
}

.footer .infoWrapper .category .subpageWrapper {
  display: flex;
  flex-direction: column;
}

.footer .infoWrapper .category .subpage {
  font-family: "Pretendard-SemiBold";
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 10px;
  text-decoration: none;
  cursor: pointer;
}

/* ... LayoutWithMenu */
.layoutWithMenu {
  width: 100%;
  height: calc(100vh - var(--header-height));
  display: flex;
  overflow: scroll;
}

.layoutWithMenu .menuBox {
  width: 20%;
  min-width: 240px;
  height: calc(100% + 200px);
  background-color: var(--ait-white);
  position: sticky;
  top: var(--sticky-top);
  transition: all 0.5s;
}

/* .layoutWithMenu .menuBox > .MuiBox-root {
  margin-bottom: 60px;
} */

.layoutWithMenu .menuBox .MuiListItemButton-root {
  padding: 20px 0 20px 6vw;
}

.layoutWithMenu .menuBox .MuiListItemButton-root.category {
  margin-top: 30px;
}

.layoutWithMenu .menuBox .MuiListItemButton-root.Mui-selected.subMenu {
  background: transparent;
}

.layoutWithMenu .menuBox .MuiListItemButton-root.subMenu {
  padding: 5px 0 5px 6vw;
}

.layoutWithMenu .menuBox .MuiListItemText-primary {
  font-family: "Pretendard-Regular";
  font-size: 20px;
}

.layoutWithMenu .menuBox .subMenu .MuiListItemText-primary {
  font-size: 16px;
}

.layoutWithMenu .menuBox .Mui-selected.subMenu .MuiListItemText-primary {
  font-family: "Pretendard-ExtraBold";
  color: var(--ait-blue);
}

.layoutWithMenu .contentBox {
  /* padding-left: 20%; */
  width: 80%;
  background-color: #fff;
}

/* subPage */
.layoutSubPage {
  padding: 8px 15% 50px 10%;
}

.layoutSubPage .title .captionBox {
  width: 105%;
  border-top: 1px solid #000;
  text-align: center;
  padding: 5vh 0 4vh 0;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layoutSubPage .title .captionBox .caption {
  width: 70%;
}

/* ... academy instrctors */
.layoutSubPage.instructors {
  margin-right: 0;
}

/* DirectorCard */
.directorCard {
  height: 320px;
  background-color: #fff;
  display: flex;
  margin: 10px 5px 40px 5px;
}

.directorCard .imgBox {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.directorCard .profileBox {
  width: 60%;
  padding: 0 5%;
  width: max-content;
  overflow: hidden;
}

.directorCard .profileBox .nameBox {
  height: 32%;
  display: flex;
  align-items: flex-end;
}

.directorCard .profileBox .nameBox .nameWrapper {
  display: flex;
  align-items: baseline;
}

.directorCard .profileBox .name {
  font-family: "Pretendard-Black";
  font-size: 28px;
  margin-right: 10px;
}

.directorCard .profileBox .position {
  font-family: "Pretendard-SemiBold";
  font-size: 16px;
}

.directorCard .profileBox .resumeBox {
  height: 58%;
  padding-top: 15px;
}

.directorCard .profileBox .resume {
  font-family: "Pretendard-Light";
  font-size: calc(10px + 0.45vw);
  margin-bottom: 5px;
}

/* InstructorCard */
.instructorCardWrapper {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  justify-content: flex-start;
}

.instructorCard {
  /* width: 260px; */
  height: 380px;

  min-height: 360px;
  height: max-content;
  /* height: 500px; */

  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0px 5px 10px 5px;
}

.instructorCard .imgBox {
  width: 100%;
  height: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.instructorCard .profileBox {
  padding: 0 7%;
  /* width: max-content; */
  overflow: hidden;
}

.instructorCard .profileBox .nameBox {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}

.instructorCard .profileBox .nameBox .nameWrapper {
  display: flex;
  align-items: baseline;
}

.instructorCard .profileBox .name {
  font-family: "Pretendard-Black";
  font-size: 20px;
  margin-right: 8px;
}

.instructorCard .profileBox .position {
  font-family: "Pretendard-SemiBold";
  font-size: 14px;
}

.instructorCard .profileBox .resumeBox {
  height: min-content;
  padding: 7px 0 10px 0;
}

.instructorCard .profileBox .resume {
  width: 100%;
  height: max-content;
  font-family: "Pretendard-Light";
  /* font-size: 14px; */
  font-size: calc(8px + 0.45vw);
  padding-bottom: 5px;
}

#boardWrapper {
  width: 80%;
}

#tagsWrapper {
  transition: all 0.2s linear;
}

#tagsOpener {
  transition: all 0.2s linear;
}

.tags {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: all 0.1s linear;
}

.post {
  transition: all 0.2s ease !important;
}

.courses .title {
  margin-bottom: 20px;
}

.courses .titleBox {
  margin-top: 30px;
  padding-top: 25px; /* origin is 20 */
  margin-bottom: 10px;
  height: 36px;
  display: flex;
  align-items: flex-end;
}

.courses .titleBox .MuiTypography-root {
  font-size: 24px;
  font-family: "Pretendard-Bold";
}

.courses .titleBox .MuiBox-root {
  height: 50%;
  width: 28%;
  margin: 0 25px;
  border-top: 3px solid;
}

.courses .elementary .titleBox .MuiTypography-root {
  color: var(--ait-blue);
}

.courses .elementary .titleBox .MuiBox-root {
  border-color: var(--ait-blue);
}

.courses .middleHigh .titleBox .MuiTypography-root {
  color: var(--ait-gray);
}

.courses .middleHigh .titleBox .MuiBox-root {
  border-color: var(--ait-gray);
}

/* curriculumPage common text style */
.courses .title > .MuiTypography-root,
.courses .courseCard .courseWrapper .MuiTypography-root,
.classGroupCard .titleBox .caption,
.classCard .caption {
  font-size: 16px;
  font-family: "Pretendard-Medium";
  line-height: 1.75;
}

/* ... courseCard */
.courses .courseCard .titleWrapper {
  margin: 15px 0;
  padding: 15px 25px;
  background-color: var(--ait-white);
  cursor: pointer;
}

.courses .courseCard .titleWrapper .MuiTypography-root {
  font-family: "Pretendard-Regular";
  font-size: 24px;
}

.courses .elementary .courseCard.selected .titleWrapper {
  background-color: var(--ait-blue);
  color: var(--ait-white);
}

.courses .middleHigh .courseCard.selected .titleWrapper {
  background-color: var(--ait-gray);
  color: var(--ait-white);
}

.courses .courseCard.selected .titleWrapper .MuiTypography-root {
  font-family: "Pretendard-Bold";
}

.courseCard .captionWrapper {
  width: 100%;
  border-top: 1px solid #bbb;
  padding: 20px 0 0px 0; /* maybe changes */
}
.courses .courseCard .courseWrapper {
  display: none;
}

.courses .courseCard.selected .courseWrapper {
  display: block;
}

.courses .courseCard .imgWrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 15px;
}

.layoutSubPage.track .componentSlider {
  height: max-content;
  position: relative;
}

.layoutSubPage.track .componentSlider .controller {
  /* height: 900px; */
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
}

.layoutSubPage.track .componentSlider .controller.left {
  height: 100%;
  width: 10vw;
  top: 0;
  left: -10vw;
}

.layoutSubPage.track .componentSlider .controller.right {
  height: 100%;
  width: 23vw;
  top: 0;
  right: -10vw;
}

.classGroupCard {
  width: 100%;
  height: 440px;
  height: max-content;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 50px 0 20px 0;
}

.classGroupCard .titleBox {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 25px;
  /* background-color: #9e00dc; */
}

.classGroupCard .titleBox .groupName {
  font-family: "Pretendard-Bold";
  font-size: 24px;
  line-height: 2;
}

.classGroupCard .classCardContainer {
  height: 70%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.classGroupCard .classCard {
  width: 240px;
  height: 330px;
  border-radius: 10px;
  border: 3px solid var(--ait-black);
  /* padding: 10px 15px; */
  margin: 0 20px 20px 0;
  overflow: hidden;
}

.classGroupCard .classCard:nth-child(1) {
  margin-left: 0;
}

.classGroupCard .thumbnailBox {
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.classGroupCard .cardTitle {
  height: 15%;
  margin: 0 15px;
  margin-bottom: 5px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 5px 0;
  border-bottom: 1px solid #777;
}

.classGroupCard .cardContent {
  height: 31%;
  margin: 0 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.classGroupCard .classCard .className {
  font-family: "Pretendard-Bold";
  font-size: 20px;
}

.classGroupCard .classCard .className.group {
  margin-right: 5px;
}

.classGroupCard .classCard .className.difficulty.basic {
  color: var(--basic-green);
}

.classGroupCard .classCard .className.difficulty.pro {
  color: var(--pro-orange);
}

.classGroupCard .classCard .className.difficulty.starter {
  color: var(--ait-yellow);
}

.classGroupCard .classCard .className.difficulty.ace {
  color: var(--ace-pink);
}

.classGroupCard .classCard .className.difficulty.master {
  color: var(--master-skyblue);
}
.classGroupCard .classCard .className {
  font-family: "Pretendard-Bold";
  font-size: 20px;
  /* padding-left: 5px; */
  margin-right: 5px;
}

.classCard .caption {
  line-height: 1.6;
}

.classGroupCard .classCard .tags {
  display: flex;
}

.classGroupCard .classCard .typeBox,
.classGroupCard .classCard .recommendBox {
  display: flex;
}

.classGroupCard .classCard .recommendItem,
.classGroupCard .classCard .typeItem {
  width: max-content;
  padding: 1px 9px;
  margin: 3px 5px 3px 0;
  border-radius: 25px;
  border: 2px solid;
  font-family: "Pretendard-Bold";
  font-size: 12px;
}
.classGroupCard .classCard .elementaryLow {
  border-color: var(--ait-yellow);
  color: var(--ait-yellow);
}
.classGroupCard .classCard .elementaryHigh {
  border-color: var(--ait-orange);
  color: var(--ait-orange);
}
.classGroupCard .classCard .middleHigh {
  border-color: var(--ait-blue);
  color: var(--ait-blue);
}

.aboutPage .brandInfo {
  width: 50vw;
  padding: 100px 25%;
  background-color: var(--ait-blue);
}

.FAQs {
  padding: 20px 6% 60px 6%;
}

.FAQCard {
  margin: 0 20px 20px 20px;
  padding: 0 30px;
  /* background-color: #4c86e5; */
}

.FAQCard .titleWrapper {
  padding: 15px 5%;
  background-color: var(--ait-white);
  cursor: pointer;
}

.FAQCard .titleWrapper .MuiTypography-root {
  font-family: "Pretendard-Regular";
  font-size: 24px;
}

.FAQCard.selected .titleWrapper {
  background-color: var(--ait-blue);
  color: var(--ait-white);
}

.FAQCard.selected .titleWrapper .MuiTypography-root {
  font-family: "Pretendard-Bold";
}

.FAQCard .captionWrapper {
  padding: 0px 5%;
  background-color: var(--ait-white);
  /* display: none; */
  /* display: none; */
  overflow: hidden;
  height: 0;
  transition: height 0.5s;
}

.FAQCard.selected .captionWrapper {
  /* display: block; */
  height: calc(var(--answer-length) * 65px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FAQCard .captionWrapper .MuiTypography-root {
  /* display: inherit; */
  font-size: 20px;
  font-family: "Pretendard-Medium";
  line-height: 1.75;
}

.mapContainer .contentBox {
  display: flex;
  padding-left: 15px;
  padding-bottom: 60px;
}

.mapContainer .contentBox .info {
  padding-left: 50px;
}

.mapContainer .contentBox .MuiTypography-root {
  font-family: "Pretendard-Medium";
  font-size: 16px;
}

.mapContainer .contentBox .itemTitle {
  font-family: "Pretendard-Bold";
  font-size: 20px;
  margin-bottom: 5px;
}

.mapContainer .contentBox .smallCaption {
  font-family: "Pretendard-Bold";
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.mapContainer .contentBox .transport,
.mapContainer .contentBox .infoWithIcon {
  display: flex;
  text-decoration: none;
  color: #000;
}
.mapContainer .contentBox .infoWithIcon {
  cursor: pointer;
}
.mapContainer .contentBox .infoWithIcon:nth-last-child(1) {
  cursor: auto;
}
.mapContainer .contentBox .transport {
  align-items: center;
}
.mapContainer .contentBox .infoWithIcon .MuiTypography-root,
.mapContainer .contentBox .infoWithIcon .MuiLink-root {
  font-size: 14px;
  font-family: "Pretendard-Bold";
  margin-left: 5px;
}
.mapContainer .contentBox .transport .MuiTypography-root:nth-child(2) {
  font-family: "Pretendard-Bold";
}
.mapContainer .contentBox .transport .MuiTypography-root:nth-child(4) {
  font-family: "Pretendard-Bold";
  color: #888;
}
.mapContainer .contentBox .item {
  margin-bottom: 20px;
}
.mapContainer .contentBox .tag {
  border-radius: 25px;
  font-size: 12px;
  font-family: "Pretendard-ExtraBold";
  color: #fff;
  padding: 0 5px;
  margin-right: 5px;
}

.mapContainer .contentBox .tag.lineNo2 {
  background-color: #00a84d;
}
.mapContainer .contentBox .tag.lineSB {
  background-color: #f5a200;
}
.mapContainer .contentBox .tag.bus {
  border-radius: 5px;
  background-color: #53b332;
}
.mapContainer .MuiTypography-root {
  margin-bottom: 5px;
}
.mapContainer .transport .MuiTypography-root {
  margin-right: 5px;
}

@media (max-width: 912px) {
  .layoutSubPage.instructors,
  .layoutSubPage.mapContainer,
  .layoutSubPage.spaceGallery {
    padding: 8px 0 30px 0;
  }

  .FAQCard.selected .captionWrapper {
    height: calc(var(--answer-length) * 100px);
  }

  .header .nav {
    padding: 0;
  }

  .header .logo {
    height: 70%;
    margin-left: 2.5%;
  }

  .symbols .captionBox {
    width: 250px;
  }

  .learningSpace .captionBox {
    /* width: 260px; */
    /* background-color: #003b99; */
    border-radius: 0px;
  }
  .learningSpace .captionBox .contentBox .logoBox {
    margin-bottom: 15px;
  }

  .learningSpace .contentBox .captionWrapper {
    margin-bottom: 10px;
  }

  .learningSpace .captionBox .content {
    font-size: 14px;
  }

  .layoutWithMenu {
    flex-direction: column;
  }

  .layoutWithMenu .menuBox {
    width: 100%;
    height: 60px;
  }

  .layoutWithMenu .menuBox .menuWrapper {
    height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .layoutWithMenu .menuBox .menuWrapper .textBox:nth-child(1) {
    padding-left: 0;
  }

  .layoutWithMenu .menuBox .menuWrapper .textBox {
    height: fit-content;
  }

  .layoutWithMenu .menuBox .menuWrapper .textBox .text {
    font-size: 18px;
    padding-left: 0;
  }

  .layoutWithMenu .contentBox {
    width: 100%;
  }

  .directorCard {
    height: max-content;
    flex-direction: column;
    margin: 0px 5px 10px 5px;
  }

  .directorCard .imgBox {
    width: 100%;
    height: 400px;
  }

  .directorCard .profileBox {
    width: 100%;
    padding: 0 7%;
  }

  .directorCard .profileBox .nameBox {
    margin-top: 10px;
  }

  .directorCard .profileBox .name {
    font-size: 20px;
    margin-right: 8px;
  }

  .directorCard .profileBox .position {
    font-size: 14px;
  }

  .directorCard .profileBox .resumeBox {
    height: min-content;
    padding: 7px 0 10px 0;
  }

  .directorCard .profileBox .resume {
    width: 86%;
    height: max-content;
    padding-bottom: 5px;
  }

  .layoutWithMenu .menuBox {
    display: none;
  }

  .courses .courseCard .titleWrapper .MuiTypography-root {
    font-size: 20px;
  }

  .academicCalendar {
    height: max-content;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .academicCalendar .calendarWrapper {
    width: 80%;
    /* height: 300px; */
    padding: 20px 10% 30px 10%;
    background-color: #fff;
  }

  .academicCalendar .scheduleWrapper {
    --horizontal-padding: 10vw;

    width: calc(100% - var(--horizontal-padding) * 2);

    padding-left: var(--horizontal-padding);
    padding-right: var(--horizontal-padding);
    margin-bottom: 30px;
    margin-top: 0px;
  }

  .academicCalendar .scheduleWrapper .title {
    font-size: 28px;
  }

  .academicCalendar .dateBox {
    width: 100%;
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #000;
  }
  .academicCalendar .dateBox:nth-last-child(1) {
    border-bottom: 0px;
  }
  .academicCalendar .dateBox .date {
    width: 55%;
  }
  .academicCalendar .dateBox .text {
    width: 45%;
  }
  .academicCalendar .dateBox .date .MuiTypography-root,
  .academicCalendar .dateBox .text {
    font-size: 14px;
  }

  .academicCalendar .dateBox .date .MuiTypography-root.start {
    width: 35%;
  }
}

@media (max-width: 600px) {
  .layoutSubPage.instructors,
  .layoutSubPage.mapContainer {
    padding: 8px 0 0 0;
  }
  .mapContainer .contentBox {
    padding-left: 18px;
  }
  .mapContainer .contentBox .info {
    margin-top: 25px;
    padding-left: 0px;
  }
  .instructorCard .profileBox .resume {
    font-size: 14px;
  }
  .aboutPage .brandInfo {
    width: 80vw;
    padding: 80px 10%;
  }

  .FAQs {
    padding: 20px 0% 60px 0%;
  }

  .FAQCard {
    padding: 0 10px;
  }

  .FAQCard .titleWrapper .MuiTypography-root {
    font-size: 16px;
  }

  .FAQCard .captionWrapper .MuiTypography-root {
    font-size: 16px;
  }

  .FAQCard.selected .captionWrapper {
    height: calc(var(--answer-length) * 100px);
  }

  .slide.instructor .captionBox .titleBox,
  .slide.semesterStart .captionBox .titleBox,
  .slide .captionBox .titleBox {
    margin-right: 0;
  }
  .header .nav {
    height: 8vh;
    padding: 0;
  }

  .header .menu {
    display: none;
  }

  .pageTitle > .main {
    font-size: 60px;
  }

  .pageTitle > .sub {
    font-size: 20px;
  }

  .pageSlider {
    height: 440px;
  }

  .pageSlider .slider .slideNavigator {
    /* visibility: hidden; */
    bottom: 20px;
  }

  .pageSlider .slider .controller.left {
    left: 0%;
  }

  .pageSlider .slider .controller.right {
    right: 0%;
  }

  .contentWrapper .arrowButton {
    width: 50px;
    height: 60px;
    margin: 0;
  }

  .contentWrapper .arrowButton .icon {
    font-size: 28px;
  }

  .pageSlider .slider .controller .Icon {
    font-size: 20px;
  }

  .slide.semesterStart .captionBox,
  .slide.instructor .captionBox,
  .slide .captionBox {
    width: 70vw;
  }

  .slide.semesterStart .captionBox .contentBox .MuiBox-root,
  .slide .captionBox .contentBox .MuiBox-root {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .slide.semesterStart .captionBox .contentBox .content,
  .slide .captionBox .contentBox .content {
    width: max-content;
    font-size: 14px;
  }

  .slide.instructor .captionBox .titleBox {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .slide .captionBox .titleBox .title,
  .slide.semesterStart .captionBox .titleBox .title,
  .slide.instructor .captionBox .titleBox .title {
    width: 300px;
    font-size: 52px;
    /* background-color: #008c00; */
  }

  .slide .captionBox .titleBox .title {
    /* font-size: 52px; */
  }

  .slide.instructor .captionBox .contentBox .content {
    width: 45vw;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .symbols .captionBox {
    width: 100%;
    position: relative;
    margin: auto auto;
    right: 0;
    top: 0;
    padding: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .symbols .captionBox .title {
    font-size: 32px;
    padding-bottom: 10px;
  }

  .symbols .captionBox .text {
    font-size: 16px;
    text-align: center;
    padding-bottom: 20px;
  }

  .learningSpace {
    max-height: 640px;
    height: 130vw;
    flex-direction: column;
    padding: 0;
  }

  .learningSpace .captionBox {
    width: 100vw;
    height: max-content;
    /* position: absolute; */
    right: 0;
    bottom: 0;
    padding: 0;
    padding-top: 35px;
    padding-bottom: 30px;
    background-color: var(--ait-gray);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .learningSpace .captionBox .titleBox {
    margin-bottom: 15px;
  }

  .learningSpace .captionBox .title {
    font-size: 28px;
  }

  .learningSpace .captionBox .contentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .learningSpace .captionBox .content {
    text-align: center;
    padding: 0 10vw;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .learningSpace .captionBox .wayToCome {
    display: none;
  }

  .cardGallery {
    flex-flow: nowrap;
    width: max-content;
    top: +0vw;
    left: -5vw;
  }

  .cardGallery .card {
    width: 38vw;
    height: 57vw;
  }

  .academicCalendar {
    height: max-content;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .academicCalendar .calendarWrapper {
    width: 100%;
    /* height: 300px; */
    padding: 20px 0 30px 0;
    background-color: #fff;
  }

  .academicCalendar .scheduleWrapper {
    --horizontal-padding: 10vw;

    width: calc(100% - var(--horizontal-padding) * 2);

    padding-left: var(--horizontal-padding);
    padding-right: var(--horizontal-padding);
    margin-bottom: 30px;
    margin-top: 0px;
  }

  .academicCalendar .scheduleWrapper .title {
    font-size: 28px;
  }

  .academicCalendar .dateBox {
    width: 100%;
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #000;
  }
  .academicCalendar .dateBox:nth-last-child(1) {
    border-bottom: 0px;
  }
  .academicCalendar .dateBox .date {
    width: 55%;
  }
  .academicCalendar .dateBox .text {
    width: 45%;
  }
  .academicCalendar .dateBox .date .MuiTypography-root,
  .academicCalendar .dateBox .text {
    font-size: 14px;
  }

  .academicCalendar .dateBox .date .MuiTypography-root.start {
    width: 35%;
  }
  .footer .infoWrapper {
    flex-direction: column;
    height: fit-content;
  }

  .footer {
    background-color: var(--ait-black);
    color: var(--ait-white);
    padding: 60px 10% 10px 10%;
  }

  .layoutSubPage {
    padding: 8px 10% 0 10%;
  }

  .courses .titleBox .MuiBox-root {
    width: auto;
    flex-grow: 1;
    margin: 0 10px 0 20px;
    border-top: 3px solid;
  }

  .courses .courseCard .titleWrapper .MuiTypography-root {
    font-size: 16px;
  }

  .courseCard .captionWrapper {
    text-align: center;
  }

  .courses .title > .MuiTypography-root,
  .courses .courseCard .courseWrapper .MuiTypography-root,
  .classGroupCard .titleBox .caption,
  .classCard .caption {
    font-size: 16px;
    line-height: 2;
  }

  .classGroupCard .classCardContainer {
    justify-content: center;
  }

  .classGroupCard .classCard {
    margin-right: 0;
    /* height: max-content; */
    min-height: 330px;
  }
}

@media (max-width: 360px) {
  .slide.instructor .captionBox,
  .slide.semesterStart .captionBox,
  .slide .captionBox {
    padding-top: 80px;
  }

  .pageSlider .slideNavigator {
    visibility: hidden;
  }
  .FAQCard.selected .captionWrapper {
    height: calc(var(--answer-length) * 120px);
  }

  .slide.instructor .arrowButton,
  .slide.semesterStart .arrowButton,
  .slide .arrowButton {
    display: none;
  }

  .slide.semesterStart .captionBox,
  .slide.instructor .captionBox,
  .slide .captionBox {
    width: min-content;
    text-align: right;
    width: 70vw;
  }

  .pageSlider {
    height: 370px;
  }

  .slide.semesterStart .captionBox .titleBox .title,
  .slide.instructor .captionBox .titleBox .title,
  .slide .captionBox .titleBox .title {
    font-size: 52px;
  }

  .slide.semesterStart .captionBox .contentBox .content,
  .slide.instructor .captionBox .contentBox .content,
  .slide .captionBox .contentBox .content {
    width: 70vw;
    text-align: right;
    margin-right: 0;
  }

  .slide.semesterStart .captionBox .contentBox .content,
  .slide .captionBox .contentBox .content {
    width: max-content;
  }

  .learningSpace .captionBox .wayToCome .blackButton {
    margin-right: 10px;
  }
}

@media (max-width: 300px) {
  .pageTitle > .main {
    font-size: 40px;
  }

  .pageTitle > .sub {
    font-size: 16px;
  }

  .slide.semesterStart .captionBox,
  .slide.instructor .captionBox,
  .slide .captionBox {
    padding-top: 45px;
  }

  .slide.semesterStart .captionBox .titleBox .title,
  .slide.instructor .captionBox .titleBox .title,
  .slide .captionBox .titleBox .title {
    font-size: 44px;
  }

  .pageSlider {
    height: 280px;
  }

  .pageSlider .slideNavigator {
    visibility: hidden;
  }

  .pageSlider .slider .controller .Icon {
    display: none;
  }

  .symbols {
    height: 300px;
  }
  .symbols .captionBox .title {
    font-size: 24px;
  }
  .symbols .captionBox .text {
    font-size: 14px;
  }

  .learningSpace {
    height: 460px;
    max-height: 100%;
  }

  .learningSpace .captionBox .title {
    font-size: 24px;
  }

  .learningSpace .captionBox .contentBox {
    height: 120px;
  }
  .learningSpace .captionBox .contentBox .content {
    font-size: 14px;
  }

  .learningSpace .captionBox .contentBox .text {
    font-family: "Pretendard-Medium";
  }

  .cardGallery {
    top: 5vw;
    left: -3vw;
  }

  .academicCalendar .scheduleWrapper .title {
    font-size: 24px;
  }

  .academicCalendar .dateBox .date {
    width: 40%;
  }
  .academicCalendar .dateBox .text {
    width: 60%;
  }

  .courses .courseCard .titleWrapper .MuiTypography-root {
    font-size: 16px;
  }

  .classGroupCard .classCard {
    width: 240px;
    height: 300px;
    margin: 0 0 10px 0;
  }

  .classCard .cardTitle .className {
    font-size: 18px;
  }

  .classCard .cardContent .caption {
    font-size: 14px;
  }

  .classCard .cardContent .recommendItem {
    font-size: 10px;
  }

  .learningSpace .contentBox img.desker {
    height: 20px;
  }

  .learningSpace .contentBox img.odd33 {
    height: 15px;
  }

  .FAQCard.selected .captionWrapper {
    height: calc(var(--answer-length) * 150px);
  }
}
